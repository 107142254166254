import {Component, forwardRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AbstractControlComponent} from "../abstract-control-value-accessor/abstract-control.component";
import {FormBuilder, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {CustomerService} from "../../core/services/customer.service";
import {map} from "rxjs/operators";
import {LegalEntity, LegalEntityList} from "../model/legal-entity.model";

@Component({
  selector: 'app-searcher-customer',
  templateUrl: './search-customer-core.component.html',
  styleUrls: ['./search-customer-core.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchCustomerCoreComponent),
      multi: true,
    },
  ],
})

export class SearchCustomerCoreComponent extends AbstractControlComponent implements OnChanges {
  @Input() label;
  @Input() required = false;
  @Input() instructing;
  @Input() instructingId: number;

  customerForm: FormControl;

  onTouched: () => void = () => {
  }

  constructor(private formBuilder: FormBuilder,
              private customerService: CustomerService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.instructingId && changes.instructingId.currentValue) {
      this.clear();
    }
  }

  writeValue(obj: any): void {
    if (obj == null) {
      this.customerForm.reset();
    } else {
      this.customerForm.setValue(obj);
    }
  }

  registerOnChange(fn: any): void {
    this.customerForm.valueChanges.subscribe(fn);
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.customerForm.disable() : this.customerForm.enable();
  }

  ngOnInit(): void {
    this.customerForm = this.formBuilder.control(null);
  }

  public customerSearchFunction = (text: string, page: number, size: number) => {
    return this.customerService.searchCore(this.instructing, text, page, size, this.instructingId).pipe(
      map(data => this.toSimpleSelectableDataList(data))
    )
  }

  public customerFindByIdFunction = (customerId) => {
    return this.customerService.getOne(customerId).pipe(
      map(customer => customer.toSelectText())
    )
  };

  clear() {
    this.customerForm?.reset();
  }

  inputChanged(value: string) {
    if (value === '') {
      this.clear();
    }
  }

  private toSimpleSelectableDataList(data: LegalEntityList) {
    return {
      data: data.data.map(el => this.mapToSelectableItem(el)),
      totalCount: data.totalCount
    };
  }

  private mapToSelectableItem(el: LegalEntity) {
    return {
      label: el.toSelectText(),
      value: el.coreId
    };
  }
}
