import {Component, forwardRef, Input} from '@angular/core';
import {FormBuilder, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {AbstractControlComponent} from "../../abstract-control-value-accessor/abstract-control.component";

@Component({
  selector: 'app-search-by-date-from-to',
  templateUrl: './search-by-date-from-to.component.html',
  styleUrls: ['./search-by-date-from-to.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchByDateFromToComponent),
      multi: true,
    },
  ],
})
export class SearchByDateFromToComponent extends AbstractControlComponent {
  @Input() label;
  dateRangeForm: FormGroup;


  constructor(private formBuilder: FormBuilder) {
    super();
    this.createForm();
  }

  ngOnInit(): void {
  }

  private createForm() {
    this.dateRangeForm = this.formBuilder.group({
      from: [null],
      to: [null]
    });
  }

  registerOnChange(fn: any): void {
    this.dateRangeForm.valueChanges.subscribe(fn);
  }



  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.dateRangeForm.disable() : this.dateRangeForm.enable();
  }

  writeValue(obj: any): void {
    if (obj == null) {
      this.dateRangeForm.reset();
    } else {
      this.dateRangeForm.setValue(obj);
    }
  }

  clear() {
    this.dateRangeForm.reset();
  }
}
