<div class="column-form" [formGroup]="form">
    <app-simple-checkbox-value-accessor [label]="column.label" formControlName="select">
    </app-simple-checkbox-value-accessor>
    <app-simple-multiple-search-select-value-accessor label="Działy"
                                               [data]="departments"
                                               appearance="standard"
                                               formControlName="departments"></app-simple-multiple-search-select-value-accessor>
    <div></div>
    <mat-error *ngIf="columnForm.invalid">Nie wybrano działu</mat-error>
</div>
