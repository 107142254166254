import {IMoney, IMoneyList, ISimpleMoney, ISimpleMoneyList} from '../model/interface/money.model.interface';
import formatter from 'format-number';
import Big from 'big.js';
import {Money, MoneyList} from '../model/money.model';
import {ObjectListHelper} from './object-list.helper';
import {MoneyCalculator} from '../utils/money-calculator';

export class MoneyHelper {

  static sumToText(instance: Money[]) {
    const moneyCalculator = new MoneyCalculator(instance);
    return moneyCalculator.sum()
      .data
      .map(money => money.toText())
      .join('\n');
  }

  static sumToHTML(instance: Money[]) {
    const moneyCalculator = new MoneyCalculator(instance);
    return moneyCalculator.sum()
      .data
      .map(money => money.toHTML())
      .join('<br>');
  }

  static tryToParseStringToMoneyValue(value: any) {
    if (typeof value === 'string') {
      value = value.replace(/,/g, '.')
        .replace(/ /g, '');
    }
    try {
      return new Big(value);
    } catch (e) {
      return null;
    }
  }

  static numberValueToText(value: number) {
    return formatter({decimal: ',', integerSeparator: ' ', padRight: 2})(value);
  }

  static moneyValueToText(value: Big) {
    return this.numberValueToText(value.toNumber());
  }

  static listToText(money: MoneyList) {
    return money.data
      .map(instance => instance.toText())
      .join('\n');
  }

  static toIMoneyList(data: MoneyList): IMoneyList {
    const iMoneyArray = data.data
      .map(money => MoneyHelper.toIMoney(money));
    return ObjectListHelper.fromArray(iMoneyArray);
  }

  static toIMoney(data: Money): IMoney {
    return {
      value: data.value.toNumber(),
      currency: data.currency
    };
  }

  static toSimpleMoneyList(data: MoneyList): ISimpleMoneyList {
    const simpleMoneyArray = data.data
      .map(money => this.toSimpleMoney(money));
    return ObjectListHelper.fromArray(simpleMoneyArray);
  }

  static toSimpleMoney(instance: Money): ISimpleMoney {
    return {
      value: instance ? instance.value.toNumber() : null,
      currencyId: instance ? instance.currency.id : null
    };
  }
}
