import {Component, forwardRef, OnDestroy, ViewChild, ViewContainerRef} from '@angular/core';
import {AvailableColumn, IExcelColumn, IExcelColumnList} from "../../model/interface/excel-column.interface";
import {FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";
import {ComponentType} from "@angular/cdk/portal";
import {NumberColumnFormComponent} from "../number-column-form/number-column-form.component";
import {
  ItemPropertiesColumnFormComponent
} from "../item-properties-column-form-component/item-properties-column-form.component";
import {
  EventTypeColumnFormComponent
} from "../event-type-column-form/event-type-column-form.component";
import {DefaultColumnFormComponent} from "../default-column-form/default-column-form.component";
import {ColumnFormComponent} from "../column-form.component";
import {AbstractControlComponent} from "../../abstract-control-value-accessor/abstract-control.component";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {LegalCaseExportService} from "../../../core/services/legal-case-export.service";
import {CurrencyColumnSelectFormComponent} from "../currency-select-form/currency-column-select-form.component";
import {DepartmentColumnFormComponent} from "../department-column-form/department-column-form.component";
import {EventTypesColumnFormComponent} from "../event-types-column-form/event-types-column-form.component";

@Component({
  selector: 'app-column-select-form',
  templateUrl: './column-select-form.component.html',
  styleUrls: ['./column-select-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColumnSelectFormComponent),
      multi: true,
    },
  ],
})
export class ColumnSelectFormComponent extends AbstractControlComponent implements OnDestroy{
  @ViewChild('container', {read: ViewContainerRef}) container: ViewContainerRef;

  availableColumns: IExcelColumn[];

  componentMap: Map<AvailableColumn, any>;

  form: FormGroup;
  private controlsRef: FormGroup[] = [];

  get columns() {
    return this.form.get('columns') as FormArray;
  }

  constructor(private fb: FormBuilder,
              private legalCaseExportService: LegalCaseExportService) {
    super();

    this._fetchExportAvailableColumns();
    this._initFormMap();
    this.form = this.fb.group({
      columns: new FormArray([])
    })
  }

  ngOnInit(): void {

  }

  registerOnChange(fn: any): void {
    this.form.valueChanges.subscribe(fn)
  }

  writeValue(obj: any): void {
    if (obj) {
      this.form.setValue(obj)
    } else {
      this.form.reset();
    }
  }

  selectAll() {
    this.controlsRef.forEach(control => control.patchValue({select: true}))
  }

  unselectAll() {
    this.controlsRef.forEach(control => control.patchValue({select: false}))
  }

  resolveComponent(column: IExcelColumn): ComponentType<ColumnFormComponent> {
    return this.componentMap.get(column.column) ?? DefaultColumnFormComponent;
  }

  private _createCheckboxForms() {
    this.availableColumns.forEach((column, index) => {
      const component = this.resolveComponent(column);
      const componentRef = this.container.createComponent(component);
      componentRef.instance.column = column; //Expression checked err
      this.columns.push(componentRef.instance.resultForm);
      this.controlsRef.push(componentRef.instance.columnForm);
    });
  }

  private _initFormMap() {
    this.componentMap = new Map()
        .set(AvailableColumn.LAST_EVENTS, NumberColumnFormComponent)
        .set(AvailableColumn.ITEM_PROPERTY, ItemPropertiesColumnFormComponent)
        .set(AvailableColumn.FIRST_EVENT_DATE, EventTypeColumnFormComponent)
        .set(AvailableColumn.INITIAL_DEBT, CurrencyColumnSelectFormComponent)
        .set(AvailableColumn.CURRENT_DEBT, CurrencyColumnSelectFormComponent)
        .set(AvailableColumn.RECOVERED_AMOUNT, CurrencyColumnSelectFormComponent)
        .set(AvailableColumn.EVENT_COUNT, EventTypeColumnFormComponent)
        .set(AvailableColumn.END_IN_DEPARTMENT_DATE, DepartmentColumnFormComponent)
        .set(AvailableColumn.LAST_EVENT_TYPE_IN_TYPES_GROUP, EventTypesColumnFormComponent)
        .set(AvailableColumn.LAST_EVENT_CONTENT_IN_TYPES_GROUP, EventTypesColumnFormComponent)
        .set(AvailableColumn.LAST_EVENT_DATE_IN_TYPES_GROUP, EventTypesColumnFormComponent)
        .set(AvailableColumn.CREATED_IN_DEPARTMENT_DATE, DepartmentColumnFormComponent);
  }

  ngOnDestroy(): void {
    this.container.clear();
  }

  private withNoDedicatedComponentFirst(a: IExcelColumn, b: IExcelColumn) {
    return Number(this.componentMap.has(a.column)) - Number(this.componentMap.has(b.column));
  }

  private _fetchExportAvailableColumns() {
    const req = this._createFetchExportAvailableColumnsRequest();
    this._handleRequest(req);
  }

  private _createFetchExportAvailableColumnsRequest() {
    return this.legalCaseExportService.getAvailableColumns();
  }

  private _handleRequest(req: Observable<IExcelColumnList>) {
    req.pipe(
        map(data => data.data)
    ).subscribe(columns => {
      this.availableColumns = columns
      this.availableColumns.sort((a,b) => this.withNoDedicatedComponentFirst(a,b));

      this._createCheckboxForms();
    });
  }

}
