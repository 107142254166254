<div class="column-form" [formGroup]="form">
  <app-simple-checkbox-value-accessor [label]="column.label" formControlName="select">
  </app-simple-checkbox-value-accessor>
  <app-simple-multiple-search-select-value-accessor label="Własność przedmiotu"
                                             [data]="properties"
                                             appearance="standard"
                                             formControlName="properties"></app-simple-multiple-search-select-value-accessor>
    <div></div>
    <mat-error *ngIf="columnForm.invalid">Nie wybrano własności</mat-error>
</div>
