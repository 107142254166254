import {AbstractControl, FormGroup} from '@angular/forms';
import moment, {Moment} from 'moment';

export class ValidatorHelper {
  static setFormValidity(form: FormGroup, valid: boolean) {
    if (valid) {
      form.setErrors(null);
    } else {
      form.setErrors({invalid: true});
    }
  }

  static notBeforeToday(date: Moment | null): boolean {
    const today = moment();
    return date !== null ? date.isSameOrAfter(today, 'day') : false;
  }

  static notAfterToday(date: Moment | null): boolean {
    const today = moment();
    return date !== null ? date.isSameOrBefore(today, 'day') : false;
  }

  static removeError(control: AbstractControl, error: string) {
    const err = control.errors; // get control errors
    if (err) {
      delete err[error]; // delete your own error
      if (!Object.keys(err).length) { // if no errors left
        control.setErrors(null); // set control errors to null making it VALID
      } else {
        control.setErrors(err); // controls got other errors so set them back
      }
    }
  }
}
