<div class="column-form" [formGroup]="form">
  <app-simple-checkbox-value-accessor [label]="column.label" formControlName="select">
  </app-simple-checkbox-value-accessor>
  <app-simple-autocomplete-value-accessor label="Zdarzenie"
                                          [data]="events"
                                          appearance="standard"
                                          formControlName="eventTypeId"></app-simple-autocomplete-value-accessor>
    <div></div>
    <mat-error *ngIf="columnForm.invalid">Nie wybrano typu zdarzenia</mat-error>
</div>
