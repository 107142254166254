import {ITotalCosts} from './interface/total-costs.model.interface';
import {MoneyList} from './money.model';
import {IMoneyHelper} from '../helper/i-money.helper';
import {Serializable} from '../interface/serializable.interface';
import {MoneyHelper} from '../helper/money.helper';

export class TotalCosts implements Serializable {
  private readonly _reinvoiced: MoneyList;
  private readonly _costs: MoneyList;

  constructor(instance: ITotalCosts) {
    this._reinvoiced = new MoneyList(instance.reinvoiced);
    this._costs = new MoneyList(instance.costs);
  }

  get reinvoiced(): MoneyList {
    return this._reinvoiced;
  }

  get costs(): MoneyList {
    return this._costs;
  }

  toText(): string {
    const reinvoicedDebtsText = this.serializeReinvoicedDebts();
    const costsDebtsText = this.serializeCostsDebts();
    return `${reinvoicedDebtsText}\n${costsDebtsText}`;
  }

  private serializeReinvoicedDebts() {
    const reinvoicedText = MoneyHelper.listToText(this._reinvoiced);
    return `Refakturowane: ${reinvoicedText}`;
  }

  private serializeCostsDebts() {
    const costsText = MoneyHelper.listToText(this._costs);
    return `Koszty: ${costsText}`;
  }

  toHTML(): string {
    const reinvoicedDebtsText = this.serializeReinvoicedDebts();
    const costsDebtsText = this.serializeCostsDebts();
    return `${reinvoicedDebtsText}<br>${costsDebtsText}`;
  }

}
