import {Money, MoneyList} from '../model/money.model';
import {ICurrency} from '../model/interface/currency.model.interface';
import * as _ from 'lodash';
import Big from 'big.js';
import {MoneyHelper} from '../helper/money.helper';

export class MoneyCalculator {
  private readonly _moneyList: Money[];

  constructor(moneyList: Money[]) {
    this._moneyList = moneyList;
  }

  private static sumMoney(moneyArray: Money[]): Big {
    return moneyArray.map(money => money.value)
      .reduce((accumulator, currentValue) => accumulator.plus(currentValue));
  }

  public sum(): MoneyList {
    const constSummedMoneyArray = this.getUniqueCurrencies()
      .map(currency => this.sumMoneyForCurrency(currency))
      .map(el => MoneyHelper.toIMoney(el));
    return new MoneyList({
      data: constSummedMoneyArray,
      totalCount: constSummedMoneyArray.length
    });
  }

  private sumMoneyForCurrency(currency: ICurrency): Money {
    const correspondingMoney = this.getMoneyForCurrency(currency);
    const sum = MoneyCalculator.sumMoney(correspondingMoney);
    return new Money({
      currency,
      value: sum.toNumber()
    });
  }

  private getMoneyForCurrency(currency: ICurrency): Money[] {
    return this._moneyList
      .filter(money => money.currency.id === currency.id);
  }

  private getUniqueCurrencies(): ICurrency[] {
    const currencies = this.getCurrencies();
    return _.uniqBy(currencies, 'id');
  }

  private getCurrencies(): ICurrency[] {
    return this._moneyList
      .map(money => money.currency);
  }
}
