import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ICurrencyList} from '../../shared/model/interface/currency.model.interface';
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  private URL = environment.backendUrl + '/currencies';

  constructor(private http: HttpClient) {
  }

  getAllCurrencies() {
    return this.http.get<ICurrencyList>(this.URL).pipe(
        map(list => new ICurrencyList(list))
    );
  }
}
