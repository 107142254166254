<h1 class="primary-color">Okresowy raport spraw</h1>
<mat-stepper linear #stepper>
    <mat-step [stepControl]="form">
        <ng-template matStepLabel>Wybór zakresu</ng-template>
        <div class="container">
            <form [formGroup]="form">
                <app-search-customer label="Zlecający" formControlName="instructingId"></app-search-customer>
                <app-search-lessor-group formControlName="instructingGroupId"></app-search-lessor-group>
                <mat-divider class="width-100 divider"></mat-divider>
                <h3 class="primary-color">Filtry alternatywne (wystarczy jeden spełniony warunek z grupy)</h3>
                <app-simple-checkbox-value-accessor label="Status - trwający" formControlName="onGoing"></app-simple-checkbox-value-accessor>
                <div class="or-indicator">lub</div>
                <app-search-by-date-from-to label="Data zakończenia w dziale" formControlName="endAt"></app-search-by-date-from-to>
                <div class="date-actions">
                    <button mat-raised-button
                            color="accent"
                            (click)="setPreviousMonth()">
                        <mat-icon>arrow_upward</mat-icon>
                        Ostatni miesiąc
                    </button>
                    <button mat-raised-button
                            color="accent"
                            (click)="setCurrentMonth()">
                        <mat-icon>arrow_upward</mat-icon>
                        Obecny miesiąc
                    </button>
                </div>
                <mat-divider class="width-100 divider"></mat-divider>
            </form>
        </div>
    </mat-step>
    <mat-step [formGroup]="columnForm">
        <ng-template matStepLabel>Wybór kolumn</ng-template>
        <app-column-select-form formControlName="columns"></app-column-select-form>
        <button mat-stroked-button class="export-button" (click)="export()">
            Pobierz
        </button>
    </mat-step>
</mat-stepper>
