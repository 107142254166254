import {Component, Input, OnInit} from '@angular/core';
import {ColumnFormComponent} from "../column-form.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {IExcelColumn} from "../../model/interface/excel-column.interface";

@Component({
  selector: 'app-default-column-select',
  templateUrl: './default-column-form.component.html',
  styleUrls: ['./default-column-form.component.css']
})
export class DefaultColumnFormComponent extends ColumnFormComponent implements OnInit {
  @Input() column: IExcelColumn;
  form: FormGroup;

  constructor(private fb: FormBuilder) {
    super();
    this.form = this.fb.group({
      [this.TYPE_FIELD]: [null, Validators.required],
      select: [false]
    });
  }

  get columnForm(): FormGroup {
    return this.form;
  }

  get resultForm(): FormGroup {
    return this.form;
  }
}
