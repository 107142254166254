import {Component, forwardRef, Input} from '@angular/core';
import {FormBuilder, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {
  ISimpleSelectableData,
  ISimpleSelectableDataList
} from '../model/interface/simple-selectable-data.model.interface';
import {HtmlViewerComponent} from "../html-viewer/html-viewer.component";
import {DialogOpener} from "../utils/dialog-opener";
import {AbstractControlComponent} from "../abstract-control-value-accessor/abstract-control.component";
import {MatFormFieldAppearance} from "@angular/material/form-field";

@Component({
  selector: 'app-simple-multiple-search-select-value-accessor',
  templateUrl: './simple-multiple-search-select-value-accessor.component.html',
  styleUrls: ['./simple-multiple-search-select-value-accessor.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SimpleMultipleSearchSelectValueAccessorComponent),
      multi: true,
    },
  ],
})
export class SimpleMultipleSearchSelectValueAccessorComponent extends AbstractControlComponent {
  @Input() required = false;
  @Input() label: string;
  @Input() data: ISimpleSelectableDataList;
  @Input() defaultSelectedValues = [];
  @Input() appearance: MatFormFieldAppearance = 'outline';

  selectFormControl: FormControl;

  get selectedValues() {
    const selected = this.selectFormControl.value as any[];
    if (selected?.length > 0) {
      return this.data.data
        .filter(v => selected.includes(v.value))
        .map(v => v.label)
    } else {
      return [] as string[];
    }
  }

  get selectedValuesString() {
    const selected = this.selectFormControl.value as any[];
    if (selected?.length > 0) {
      return this.data.data
        .filter(v => selected.includes(v.value))
        .map(v => v.label)
        .join(', ');
    } else {
      return ' ';
    }
  }

  constructor(private formBuilder: FormBuilder,
              private _dialogOpener: DialogOpener) {
    super();
    this.createForm();
  }

  ngOnInit(): void {
    this.writeValue(this.defaultSelectedValues);
  }

  registerOnChange(fn: any): void {
    this.selectFormControl.valueChanges.subscribe(fn);
  }



  writeValue(value: any): void {
    this.selectFormControl.setValue(value);
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.selectFormControl.disable() : this.selectFormControl.enable();
  }

  compareWith(first: number, second: number): boolean {
    if (!first || !second) {
      return false;
    }
    return first === second;
  }

  private createForm() {
    this.selectFormControl = this.formBuilder.control(null);
  }

  clear() {
    this.selectFormControl.reset();
  }

  peek(instance: ISimpleSelectableData) {
    this._dialogOpener.open(HtmlViewerComponent, {data: instance.tooltip})
  }
}
