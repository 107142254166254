import {Component, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {EventsSummaryService} from '../../../core/services/events-summary.service';
import {IEventsSummary} from '../../model/interface/events-summary';
import {IExcelColumn} from '../../model/interface/excel-column.interface';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {EventService} from '../../../core/services/event.service';
import {ISimpleSelectableDataList} from '../../model/interface/simple-selectable-data.model.interface';
import {map} from 'rxjs/operators';
import {IEventTypeHelper} from '../../helper/i-event-type.helper';
import {RequestProcessor} from '../../utils/request-processor';
import {DefaultColumnFormComponent} from "../../column-select/default-column-form/default-column-form.component";

@Component({
  selector: 'app-events-summary',
  templateUrl: './events-summary.component.html',
  styleUrls: ['./events-summary.component.css']
})
export class EventsSummaryComponent implements OnInit, OnDestroy {
  availableEventType: ISimpleSelectableDataList;
  isEditable = true;
  firstFormGroup: FormGroup

  @ViewChild('container', {read: ViewContainerRef}) container: ViewContainerRef;

  availableColumns: IExcelColumn[];
  form: FormGroup;

  get columns() {
    return this.form.get('columns') as FormArray;
  }

  constructor(private eventsSummaryService: EventsSummaryService,
              private eventService: EventService,
              private matDialogRef: MatDialogRef<EventsSummaryComponent>,
              private requestProcessor: RequestProcessor,
              private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.createForm()
    this.fetchAvailableEventType();
    this.fetchAvailableColumn();
    this.form = this.fb.group({
      columns: new FormArray([])
    })
  }

  createForm() {
    this.firstFormGroup = this.fb.group({
      date: [null, Validators.required],
      types: [null, Validators.required]
    });
  }

  fetchAvailableEventType() {
    this.eventService.getAllAvailableForUserTypes()
      .pipe(
        map((eventTypes) => IEventTypeHelper.toSimpleSelectableDataList(eventTypes))
      ).subscribe(eventType => {
      this.availableEventType = eventType
    });
  }

  fetchAvailableColumn() {
    this.eventsSummaryService.getColumns().subscribe(columns => {
      this.availableColumns = columns.data;
    });
  }

  selectAll() {
    this.columns.controls.filter(c => !(c instanceof FormArray)).forEach(control => control.patchValue({select: true}))
  }

  unselectAll() {
    this.columns.controls.filter(c => !(c instanceof FormArray)).forEach(control => control.patchValue({select: false}))
  }

  protected _createCheckboxForms() {
    this.container.clear();
    this.availableColumns.forEach((column) => {
      const componentRef = this.container.createComponent(DefaultColumnFormComponent);
      componentRef.instance.column = column;
      this.columns.push(componentRef.instance.resultForm);
    });
  }

  ngOnDestroy(): void {
    this.container.clear();
  }

  export() {
    const payload = this._createPayload(this.firstFormGroup.value)
    const data = this.eventsSummaryService.export(payload)
    this.requestProcessor.saveResponseAsFile(data);
    this.matDialogRef.close();
  }

  private _createPayload(form) {
    const selectedColumns = this.getSelectedColumns();
    return {
      from: form.date?.from.format('YYYY-MM-DD'),
      to: form.date?.to.format('YYYY-MM-DD'),
      eventsTypesId: form.types,
      columns: selectedColumns
    } as IEventsSummary
  }

  getSelectedColumns() {
    return this.columns.value.flat()
      .filter(column => column.select)
      .map(({select, label, ...rest}) => rest);
  }
}
