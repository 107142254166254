import {Injectable} from '@angular/core';
import {WebsocketObservableMapper} from "./websocket-observable.mapper";
import {Observable} from "rxjs";
import {IMessage} from "@stomp/stompjs";
import {filter, map} from "rxjs/operators";
import {DpdContract} from "../model/dpd-contract.model";
import {IDpdContract} from "../model/interface/dpd-contract.model.interface";

@Injectable({
  providedIn: 'root'
})
export class DpdContractObservableMapper implements WebsocketObservableMapper<DpdContract> {
  private static checkIfContractIsValid(contract: DpdContract) {
    return contract !== null && contract !== undefined;
  }

  private static deserializeIMessageToIContract(message: IMessage) {
    return JSON.parse(message.body) as IDpdContract;
  }

  constructor() { }

  fromWebsocketMessage(observable: Observable<IMessage>): Observable<DpdContract> {
    return observable.pipe(
      map(message => DpdContractObservableMapper.deserializeIMessageToIContract(message)),
      map(iContractItem => this.convertIContractItemToEntity(iContractItem)),
      filter(contract => DpdContractObservableMapper.checkIfContractIsValid(contract))
    );
  }

  private convertIContractItemToEntity(instance: IDpdContract): DpdContract {
    return new DpdContract(instance);
  }
}
