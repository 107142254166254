import {IContractView} from './interface/contract-view.model.interface';
import {Serializable} from '../interface/serializable.interface';

export abstract class ContractView implements Serializable {
  private readonly _id: number;
  private readonly _number: string;

  protected constructor(instance: IContractView) {
    this._id = instance.id;
    this._number = instance.number;
  }

  get id(): number {
    return this._id;
  }

  get number(): string {
    return this._number;
  }

  toHTML(): string {
    return this._number;
  }

  toText(): string {
    return this._number;
  }
}
