import {ObjectList} from './object-list.model.interface';
import {ISimpleSelectableData} from "./simple-selectable-data.model.interface";

export interface ICurrency {
  id: number;
  name: string;
  symbol: string;
  shortcut: string;
}

export class ICurrencyList extends ObjectList<ICurrency,ICurrency> {
  protected mapToListElement(instance: ICurrency): ICurrency {
    return instance;
  }

  protected mapToSelectableItem(instance: ICurrency): ISimpleSelectableData {
    return {
      value: instance.id,
      label: instance.shortcut
    };
  }
}


