import {ObjectList} from './object-list.model.interface';
import {ISimpleSelectableData} from './simple-selectable-data.model.interface';

export enum AvailableColumn {
  BRIEFCASE = 'BRIEFCASE',
  DEPARTMENT = 'DEPARTMENT',
  LAST_EVENTS = 'LAST_EVENTS',
  CRIMINAL_LAWYER = 'CRIMINAL_LAWYER',
  LESSEE = 'LESSEE',
  FIRST_EVENT_DATE = 'FIRST_EVENT_DATE',
  REPRESENTATIVE = 'REPRESENTATIVE',
  ITEM_PROPERTY = 'ITEM_PROPERTY',
  EVENT_DATE = 'EVENT_DATE',
  EVENT_BRIEFCASE = 'EVENT_BRIEFCASE',
  EVENT_PLACE = 'EVENT_PLACE',
  EVENT_DESCRIPTION= 'EVENT_DESCRIPTION',
  EVENT_PARCEL_NUMBER = 'EVENT_PARCEL_NUMBER',
  LEGAL_CASE_DATE = "LEGAL_CASE_DATE",
  LAST_EVENT_DATE = "LAST_EVENT_DATE",
  STATE = "STATE",
  EXTERNAL_ID = "EXTERNAL_ID",
  INITIAL_DEBT = "INITIAL_DEBT",
  RECOVERED_AMOUNT = "RECOVERED_AMOUNT",
  EVENT_COUNT = "EVENT_COUNT",
  END_IN_DEPARTMENT_DATE = "END_IN_DEPARTMENT_DATE",
  CREATED_IN_DEPARTMENT_DATE = "CREATED_IN_DEPARTMENT_DATE",
  SUPERVISOR = "SUPERVISOR",
  LAST_EVENT_TYPE_IN_TYPES_GROUP = "LAST_EVENT_TYPE_IN_TYPES_GROUP",
  LAST_EVENT_CONTENT_IN_TYPES_GROUP = "LAST_EVENT_CONTENT_IN_TYPES_GROUP",
  LAST_EVENT_DATE_IN_TYPES_GROUP = "LAST_EVENT_DATE_IN_TYPES_GROUP",
  IS_ON_GOING = "IS_ON_GOING",
  END_AT = "END_AT",
  CURRENT_DEBT = "CURRENT_DEBT"
}

export interface IExcelColumn {
  column: AvailableColumn,
  label: string,
}

export class IExcelColumnList extends ObjectList<IExcelColumn, IExcelColumn> {
  protected mapToListElement(instance: IExcelColumn): IExcelColumn {
    return instance;
  }

  protected mapToSelectableItem(instance: IExcelColumn): ISimpleSelectableData {
    return {
      label: instance.label,
      value: instance.column
    };
  }

}
