import {Component, Input} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ColumnFormComponent} from "../column-form.component";
import {map} from "rxjs/operators";
import {IExcelColumn} from "../../model/interface/excel-column.interface";
import {ISimpleSelectableDataList} from "../../model/interface/simple-selectable-data.model.interface";
import {EventService} from "../../../core/services/event.service";
import {CustomValidators} from "../../validator/customValidators";

@Component({
  selector: 'app-first-notification-date-column-form',
  templateUrl: './event-type-column-form.component.html',
  styleUrls: ['./event-type-column-form.component.css']
})
export class EventTypeColumnFormComponent extends ColumnFormComponent {
  @Input() column: IExcelColumn;
  form: FormGroup;
  events: ISimpleSelectableDataList;

  constructor(private fb: FormBuilder,
              private eventService: EventService) {
    super();
    this.form = this.fb.group({
      [this.TYPE_FIELD]: [null, Validators.required],
      select: [false],
      eventTypeId: [null, CustomValidators.conditionalValidator(() => this.select.value,
        Validators.required)]
    });

    this.select.valueChanges
      .subscribe(_ => this.eventTypeId.updateValueAndValidity());
  }

  ngOnInit() {
    super.ngOnInit();
    this.eventService.getAllAvailableForUserTypes().pipe(
      map(data => data.toSimpleSelectableDataList())
    ).subscribe(data => this.events = data);
  }


  get eventTypeId() {
    return this.form.get('eventTypeId');
  }

  get select() {
    return this.form.get('select');
  }

  get columnForm(): FormGroup {
    return this.form;
  }

  get resultForm(): FormGroup {
    return this.form;
  }

}
