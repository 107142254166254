import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AvailableDepartmentType, IDepartment, IDepartmentList} from '../../shared/model/interface/department.model.interface';
import {map} from 'rxjs/operators';
import {IObjectList} from '../../shared/model/interface/object-list.model.interface';
import {Cacheable} from "ngx-cacheable";

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {
  private static readonly URL = environment.backendUrl + '/departments';

  constructor(private httpClient: HttpClient) {
  }

  getAll() {
    return this.httpClient.get<IObjectList<IDepartment>>(DepartmentService.URL)
      .pipe(
        map(data => new IDepartmentList(data))
      );
  }

  @Cacheable()
  getOne(type: AvailableDepartmentType) {
    return this.httpClient.get<IDepartment>(DepartmentService.URL + `/${type}`);
  }
}
