<h2 mat-dialog-title>Import wycen</h2>
<mat-dialog-content>
    <mat-stepper linear #stepper>
        <form [formGroup]="importValuationFormGroup">
            <mat-step [stepControl]="lessor"
                      label="Zlecający">
                <mat-card class="width-100 mat-elevation-z0">
                    <mat-card-content>
                        <app-search-customer formControlName="lessorId"
                                             [instructing]="true"
                                                                class="width-100">

                        </app-search-customer>
                    </mat-card-content>
                    <mat-card-actions>
                        <button mat-raised-button
                                color="primary"
                                [disabled]="lessor.invalid"
                                matStepperNext>
                            Dalej
                        </button>
                    </mat-card-actions>
                </mat-card>
            </mat-step>
            <mat-step [stepControl]="file"
                      label="Plik z wycenami">
                <ng-template matStepContent>
                    <mat-card class="width-100 mat-elevation-z0">
                        <mat-card-content>
                            <app-simple-file-select label="Plik z wycenami"
                                                    formControlName="file">
                            </app-simple-file-select>
                        </mat-card-content>
                        <mat-card-actions>
                            <button mat-stroked-button
                                    color="primary"
                                    matStepperPrevious>
                                Wstecz
                            </button>
                            <button mat-raised-button
                                    action-button
                                    color="primary"
                                    matStepperNext
                                    [disabled]="innerFormGroup.invalid"
                                    [action]="importValuations.bind(this)">
                                Importuj wyceny
                            </button>
                        </mat-card-actions>
                    </mat-card>
                </ng-template>
            </mat-step>
        </form>
    </mat-stepper>
</mat-dialog-content>
