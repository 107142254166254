import {ObjectList} from './object-list.model.interface';
import {ISimpleSelectableData} from './simple-selectable-data.model.interface';


export enum AvailableDepartmentType {
  INTERNATIONAL = ('INTERNATIONAL'),
  INITIAL_VERIFICATION = ('INITIAL_VERIFICATION'),
  PREVINDICATION = ('PREVINDICATION'),
  REGULAR_ACCOUNT = ('REGULAR_ACCOUNT'),
  MONITORED_PAYMENT = ('MONITORED_PAYMENT'),
  VINDICATION = ('VINDICATION'),
  RELOCATION_AND_RELEASE = ('RELOCATION_AND_RELEASE'),
  ADMINISTRATION_CASE = ('LAW_ADMINISTRATION'),
  CIVIL_CASE = ('LAW_CIVIL'),
  CRIMINAL_CASE = ('LAW_CRIMINAL'),
  SEARCHER = ('SEARCHER'),
  MY_CASES = ('MY_CASES'),
}

export interface IDepartment {
  id: number;
  name: string;
  order: number;
  type: AvailableDepartmentType;
}

export class IDepartmentList extends ObjectList<IDepartment, IDepartment> {
  protected mapToListElement(instance: IDepartment): IDepartment {
    return instance;
  }

  protected mapToSelectableItem(instance: IDepartment): ISimpleSelectableData {
    return {
      value: instance.id,
      label: instance.name
    };
  }

}
