import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {IExcelColumnList} from "../../shared/model/interface/excel-column.interface";
import {map} from "rxjs/operators";
import {Base64FileHelper} from "../../shared/helper/base64-file.helper";
import {IBase64File} from "../../shared/model/interface/base64-file.model.interface";
import {IExcelExportRequest} from "../../shared/model/interface/iexcel-export-request.interface";
import {HttpParamsFactory} from "../../shared/factory/http-params.factory";
import {ILegalCaseSearch} from "../../shared/model/interface/legal-case-search.model.interface";

@Injectable({
  providedIn: 'root'
})
export class LegalCaseExportService {
  private URL = environment.backendUrl + '/legal-cases/export';

  constructor(private http: HttpClient) {
  }

  public getAvailableColumns() {
    return this.http.get<IExcelColumnList>(this.URL + '/columns');
  }

  export(search: ILegalCaseSearch,exportRequest: IExcelExportRequest) {
    const params = HttpParamsFactory.notNullsFromObject(search)
    return this.http.post<IBase64File>(this.URL, exportRequest, {
      params
    }).pipe(
      map(file => Base64FileHelper.decodeFilename(file))
    );
  }

  exportAsync(search: ILegalCaseSearch,exportRequest: IExcelExportRequest) {
    const params = HttpParamsFactory.notNullsFromObject(search)
    return this.http.post<void>(`${this.URL}/async`, exportRequest, {
      params
    });
  }
}
