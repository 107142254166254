import {Contract} from './contract.model';
import {ObjectList} from './interface/object-list.model.interface';
import {ISimpleSelectableData} from './interface/simple-selectable-data.model.interface';
import {IDpdContract} from './interface/dpd-contract.model.interface';
import {MoneyList} from './money.model';
import {LegalEntityList} from "./legal-entity.model";
import {CustomerHelper} from "../helper/customer.helper";
import {CustomerFactory} from "../factory/customer.factory";

export class DpdContract extends Contract {
  private _currentMoney: MoneyList;
  private _guarantors: LegalEntityList;

  constructor(instance: IDpdContract) {
    super(instance);
    this._currentMoney = new MoneyList(instance.currentMoney);
    this._guarantors = CustomerFactory.fromList(instance.guarantors);
  }

  set currentMoney(value: MoneyList) {
    this._currentMoney = value;
  }

  get currentMoney(): MoneyList {
    return this._currentMoney;
  }

  get guarantors(): LegalEntityList {
    return this._guarantors;
  }
}

export class DpdContractList extends ObjectList<DpdContract, IDpdContract> {
  protected mapToListElement(instance: IDpdContract): DpdContract {
    return new DpdContract(instance);
  }

  protected mapToSelectableItem(instance: DpdContract): ISimpleSelectableData {
    return undefined;
  }
}
