import {ISimpleSelectableData, ISimpleSelectableDataList} from '../model/interface/simple-selectable-data.model.interface';
import {ObjectListHelper} from './object-list.helper';
import {IContractType, IContractTypeList} from "../model/interface/contract-type.model.interface";

export class ContractTypeHelper {
  static fromListToSelectableList(list: IContractTypeList): ISimpleSelectableDataList {
    const array = list.data
      .map(kind => ContractTypeHelper.toSelectableItem(kind));
    return ObjectListHelper.fromArray(array);
  }

  private static toSelectableItem(instance: IContractType): ISimpleSelectableData {
    return {
      label: instance.label,
      value: instance.id
    };
  }
}
