import {Component, Input} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ColumnFormComponent} from "../column-form.component";
import {IExcelColumn} from "../../model/interface/excel-column.interface";
import {CustomValidators} from "../../validator/customValidators";

@Component({
  selector: 'app-last-events-column-form',
  templateUrl: './number-column-form.component.html',
  styleUrls: ['./number-column-form.component.css']
})
export class NumberColumnFormComponent extends ColumnFormComponent {
  @Input() column: IExcelColumn;
  form: FormGroup;

  constructor(private fb: FormBuilder) {
    super();
    this.form = this.fb.group({
      [this.TYPE_FIELD]: [null, Validators.required],
      select: [false],
      number: [1, CustomValidators.conditionalValidator(() => this.select.value,
        Validators.required,
        Validators.min(1))]
    });

    this.select.valueChanges
      .subscribe(_ => this.eventsNumber.updateValueAndValidity());
  }

  get eventsNumber() {
    return this.form.get('number');
  }

  get select() {
    return this.form.get('select');
  }

  get columnForm(): FormGroup {
    return this.form;
  }

  get resultForm(): FormGroup {
    return this.form;
  }
}
