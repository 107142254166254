<h2 mat-dialog-title>Import wpłat</h2>
<mat-dialog-content>
  <mat-stepper linear #stepper>
    <form [formGroup]="importPaymentsFormGroup">
      <mat-step [stepControl]="lessor"
                label="Zlecający">
        <mat-card class="width-100 mat-elevation-z0">
          <mat-card-content>
            <app-import-payments-select-instructing formControlName="lessorId"
                                                       class="width-100">

            </app-import-payments-select-instructing>
          </mat-card-content>
          <mat-card-actions>
            <button mat-raised-button
                    color="primary"
                    [disabled]="lessor.invalid"
                    matStepperNext>
              Dalej
            </button>
          </mat-card-actions>
        </mat-card>
      </mat-step>
      <mat-step [stepControl]="file"
                label="Plik z płatnościami">
        <ng-template matStepContent>
          <mat-card class="width-100 mat-elevation-z0">
            <mat-card-content>
              <app-import-payments-select-file formControlName="file"
                                                  class="width-100">
              </app-import-payments-select-file>
            </mat-card-content>
            <mat-card-actions>
              <button mat-stroked-button
                      color="primary"
                      matStepperPrevious>
                Wstecz
              </button>
              <button mat-raised-button
                      action-button
                      color="primary"
                      matStepperNext
                      [disabled]="importPaymentsFormGroup.invalid"
                      [action]="importLegalCases.bind(this)">
                Załaduj zlecenia
              </button>
            </mat-card-actions>
          </mat-card>
        </ng-template>
      </mat-step>
    </form>
  </mat-stepper>
</mat-dialog-content>
