import {IScheduleType} from './interface/schedule-type.model.interface';
import {ISchedule} from './interface/schedule.model.interface';
import {ObjectList} from './interface/object-list.model.interface';
import {ISimpleSelectableData} from './interface/simple-selectable-data.model.interface';

export class Schedule {
  private readonly _id: number;
  private readonly _noInstallments: number;
  private readonly _type: IScheduleType;
  private readonly _active: boolean;

  constructor(instance: ISchedule) {
    this._id = instance.id;
    this._noInstallments = instance.noInstallments;
    this._type = instance.type;
    this._active = instance.active;
  }

  get id(): number {
    return this._id;
  }

  get noInstallments(): number {
    return this._noInstallments;
  }

  get type(): IScheduleType {
    return this._type;
  }

  get active(): boolean {
    return this._active;
  }
}

export class ScheduleList extends ObjectList<Schedule, ISchedule> {
  protected mapToListElement(instance: ISchedule): Schedule {
    return new Schedule(instance);
  }

  protected mapToSelectableItem(instance: Schedule): ISimpleSelectableData {
    return undefined;
  }

}
