import {Component, Input, OnInit} from '@angular/core';
import {IExcelColumn} from "../../model/interface/excel-column.interface";
import {ISimpleSelectableDataList} from "../../model/interface/simple-selectable-data.model.interface";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {map} from "rxjs/operators";
import {CustomValidators} from "../../validator/customValidators";
import {ColumnFormComponent} from "../column-form.component";
import {EventService} from "../../../core/services/event.service";

@Component({
  selector: 'app-event-types-column-form',
  templateUrl: './event-types-column-form.component.html',
  styleUrls: ['./event-types-column-form.component.css']
})
export class EventTypesColumnFormComponent extends ColumnFormComponent {

  @Input() column: IExcelColumn;
  eventTypes: ISimpleSelectableDataList;
  form: FormGroup;

  get select() {
    return this.form.get('select');
  }

  get props() {
    return this.form.get('eventTypeIds');
  }

  get columnForm(): FormGroup {
    return this.form;
  }

  get resultForm(): FormGroup {
    return this.form;
  }

  constructor(private fb: FormBuilder,
              private eventTypeService: EventService) {
    super();
    this.form = this.createColumnForm();

    this.select.valueChanges
        .subscribe(_ => this.props.updateValueAndValidity());
  }

  private createColumnForm() {
    return this.fb.group({
      [this.TYPE_FIELD]: [null, Validators.required],
      select: [false],
      eventTypeIds: [[], CustomValidators.conditionalValidator(() => this.select.value,
          Validators.required)]
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.fetchEventTypes();
  }

  private fetchEventTypes() {
    this.eventTypeService.getAllAvailableForUserTypes().pipe(
        map(data => data.toSimpleSelectableDataList())
    ).subscribe(data => this.eventTypes = data);
  }

}
