import {Component, Input} from '@angular/core';
import {ColumnFormComponent} from "../column-form.component";
import {IExcelColumn} from "../../model/interface/excel-column.interface";
import {ISimpleSelectableDataList} from "../../model/interface/simple-selectable-data.model.interface";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {map} from "rxjs/operators";
import {CustomValidators} from "../../validator/customValidators";
import {CurrencyService} from "../../../core/services/currency.service";

@Component({
  selector: 'app-currency-select-form',
  templateUrl: './currency-column-select-form.component.html',
  styleUrls: ['./currency-column-select-form.component.css']
})
export class CurrencyColumnSelectFormComponent extends ColumnFormComponent {

  @Input() column: IExcelColumn;
  currencies: ISimpleSelectableDataList;
  form: FormGroup;
  innerForm: FormArray;

  get select() {
    return this.form.get('select');
  }

  get props() {
    return this.form.get('currencies');
  }

  get columnForm(): FormGroup {
    return this.form;
  }

  get resultForm(): FormArray {
    return this.innerForm;
  }

  constructor(private fb: FormBuilder,
              private currencyService: CurrencyService) {
    super();
    this.form = this.createColumnForm();
    this.innerForm = this.createInnerForm()

    this.select.valueChanges
        .subscribe(_ => this.props.updateValueAndValidity());


    this.form.valueChanges
        .pipe(map(_ => this.flat(this.form.value)))
        .subscribe(controls => this.replaceInnerFormWithControls(controls));


  }

  private replaceInnerFormWithControls(controls: FormGroup[]) {
    this.innerForm.clear();
    controls.forEach(c => this.innerForm.push(c));
  }

  private createInnerForm() {
    return this.fb.array([]);
  }

  private createColumnForm() {
    return this.fb.group({
      [this.TYPE_FIELD]: [null, Validators.required],
      select: [false],
      currencies: [[], CustomValidators.conditionalValidator(() => this.select.value,
          Validators.required)]
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.fetchCurrencies();
  }

  flat(form): FormGroup[] {
    return form.currencies?.map(
        currencyId => this.fb.group({
          [this.TYPE_FIELD]: form[this.TYPE_FIELD],
          select: form.select,
          currencyId: currencyId
        })
    )
  }

  private fetchCurrencies() {
    this.currencyService.getAllCurrencies().pipe(
        map(data => data.toSimpleSelectableDataList())
    ).subscribe(data => this.currencies = data);
  }

  public valid(): boolean {
    return this.select.value && Array.isArray(this.props.value) && this.props.value.length === 0;
  }
}
