import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {tap} from "rxjs/operators";
import {DictionaryPosition} from "../../model/dictionary.model";
import {Observable} from "rxjs";
import {ISimpleSelectableDataList} from "../../model/interface/simple-selectable-data.model.interface";
import {EventBusService} from "../../../core/services/event-bus.service";
import {ImporterService} from "../../../core/services/importer.service";
import {MatDialogRef} from "@angular/material/dialog";
import {IBase64File} from "../../model/interface/base64-file.model.interface";

@Component({
  selector: 'app-import-legal-cases',
  templateUrl: './import-payments.component.html',
  styleUrls: ['./import-payments.component.css']
})
export class ImportPaymentsComponent implements OnInit {
  supervisors: ISimpleSelectableDataList;
  importPaymentsFormGroup: FormGroup;

  constructor(private _formBuilder: FormBuilder,
              private _eventBusService: EventBusService,
              private _importerService: ImporterService,
              private _dialogRef: MatDialogRef<ImportPaymentsComponent>) {
    this._createForm();
  }

  ngOnInit(): void {
  }

  importLegalCases() {
    const payload = this._createImportLegalCasesPayload();
    const request = this._createImportLegalCasesRequest(payload);
    return this._handleRequest(request);
  }

  private _createForm() {
    this.importPaymentsFormGroup = this._formBuilder.group({
      lessorId: [null, Validators.required],
      file: [null, Validators.required]
    })
  }


  get lessor() {
    return this.importPaymentsFormGroup.get('lessorId') as FormControl;
  }

  get file() {
    return this.importPaymentsFormGroup.get('file') as FormControl;
  }

  private _createImportLegalCasesRequest(payload: IBase64File) {
    return this._importerService.importBalance(this.importPaymentsFormGroup.value.lessorId, payload)
      .pipe(
        tap(_ => this._eventBusService.display(DictionaryPosition.OK))
      )
  }

  private _createImportLegalCasesPayload(): IBase64File {
    const value = this.importPaymentsFormGroup.value;
    return value.file;
  }

  private _handleRequest(request: Observable<any>) {
    return request.subscribe(_ => this._dialogRef.close(true));
  }
}
