import {FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators,} from '@angular/forms';
import {Money} from '../model/money.model';
import Big from 'big.js';
import {ValidatorHelper} from '../helper/validator.helper';

export class CustomValidators {
  static readonly onlyLettersValidator = Validators.compose([
    Validators.pattern('^[ A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]*$'),
  ]);

  static readonly onlyNumbersValidator = Validators.compose([
    Validators.pattern('^[0-9]*$'),
  ]);

  static readonly postalCodeValidator = Validators.compose([
    Validators.pattern('^[0-9]{2}-[0-9]{3}$'),
  ]);

  static readonly phoneValidator = Validators.compose([
    Validators.pattern('^(\\d{9}|\\+\\d{11})$')
  ]);

  static readonly surnameValidator = Validators.compose([
    Validators.pattern('^[- A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]*$'),
  ]);

  static readonly nameValidator = Validators.compose([
    CustomValidators.onlyLettersValidator,
  ]);

  static readonly NIPValidator = Validators.compose([
    Validators.pattern('^[0-9]*$'),
    Validators.maxLength(10),
    Validators.minLength(10),
  ]);

  static readonly peselValidator = Validators.compose([
    Validators.pattern('^[0-9]*$'),
    Validators.maxLength(11),
    Validators.minLength(11),
  ]);

  static readonly REGONValidator = Validators.compose([
    CustomValidators.onlyNumbersValidator,
    Validators.maxLength(14),
    Validators.minLength(9),
  ]);

  static readonly MoneyValueValidator = Validators.compose([
    Validators.pattern('^[0-9\\s]+([,]{1}[0-9]{1,2})?$'),
  ]);

  static MoneyValidator(control: FormControl) {
    const money = control.value;
    if (!money) {
      return null;
    }
    if (money && money.currency) {
      if (money.value instanceof Big) {
        return null;
      }
    }

    return {
      money: true
    };
  }

  static readonly atLeastOne = (
    validator: ValidatorFn,
    controls: string[] = null) => (group: FormGroup): ValidationErrors | null => {
    if (!controls) {
      controls = Object.keys(group.controls);
    }

    const hasAtLeastOne =
      group &&
      group.controls &&
      controls.some((k) => !validator(group.controls[k]));

    return hasAtLeastOne
      ? null
      : {
        atLeastOne: true,
      };
  };

  static readonly atLeastOneIsTrue = (controls: string[] = null) => (group: FormGroup): ValidationErrors | null => {
    if (!controls) {
      controls = Object.keys(group.controls);
    }

    const hasAtLeastOne =
      group &&
      group.controls &&
      controls.filter((k) => group.controls[k].value === true).length > 0;

    return hasAtLeastOne
      ? null
      : {
        atLeastOneTrue: true,
      };
  };

  static readonly MoneyValueNotGraterThan = (
    controlName: string,
    matchingControlName: string) => {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      const controlMoney: Money = control.value;
      const matchingControlMoney: Money = matchingControl.value;

      if (controlMoney == null || matchingControlMoney == null) {
        return;
      }

      const controlMoneyValue = controlMoney.value;
      const matchingControlMoneyValue = matchingControlMoney.value;

      if (controlMoneyValue == null || matchingControlMoneyValue == null) {
        matchingControl.setErrors({numberValueNotGraterThan: true});
        return;
      }

      // set error on matchingControl if validation fails
      if (controlMoneyValue.gte(matchingControlMoneyValue)) {
        matchingControl.setErrors({numberValueNotGraterThan: true});
      } else {
        ValidatorHelper.removeError(control, 'numberValueNotGraterThan');
        ValidatorHelper.removeError(matchingControl, 'numberValueNotGraterThan');
      }
    };
  };

  static readonly NumberNotGraterThan = (
    controlName: string,
    matchingControlName: string) => {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      const controlValue: number = control.value;
      const matchingControlValue: number = matchingControl.value;

      if (controlValue == null || matchingControlValue == null) {
        // matchingControl.setErrors({numberValueNotGraterThan: true});
        return;
      }

      // set error on matchingControl if validation fails
      if (controlValue > matchingControlValue) {
        matchingControl.setErrors({numberValueNotGraterThan: true});
      } else {
        ValidatorHelper.removeError(control, 'numberValueNotGraterThan');
        ValidatorHelper.removeError(matchingControl, 'numberValueNotGraterThan');
      }
    };
  };

  static OnlyOneCanExist = (
    first: string,
    second: string
  ) => {
    return (formGroup: FormGroup) => {
      const firstControl = formGroup.controls[first];
      const secondControl = formGroup.controls[second];

      if (firstControl == null || secondControl == null) {
        return;
      }

      const firstControlValue = firstControl.value;
      const secondControlValue = secondControl.value;

      if (!firstControlValue && !secondControlValue) {
        ValidatorHelper.removeError(firstControl, 'onlyOneCanExist')
        ValidatorHelper.removeError(secondControl, 'onlyOneCanExist')
      } else if (firstControlValue && !secondControlValue || !firstControlValue && secondControlValue) {
        ValidatorHelper.removeError(firstControl, 'onlyOneCanExist')
        ValidatorHelper.removeError(secondControl, 'onlyOneCanExist')
      } else if (firstControlValue && secondControlValue) {
        firstControl.setErrors({onlyOneCanExist: {value: secondControl}})
        secondControl.setErrors({onlyOneCanExist: {value: firstControl}})
      }
    }
  }


  static OneCantExistsWithoutAnother = (
    first: string,
    second: string
  ) => {
    return (formGroup: FormGroup) => {
      const firstControl = formGroup.controls[first];
      const secondControl = formGroup.controls[second];

      const firstControlValue = firstControl.value;
      const secondControlValue = secondControl.value;

      if (!firstControlValue && !secondControlValue) {
        ValidatorHelper.removeError(firstControl, 'oneCantExistsWithoutAnother');
        ValidatorHelper.removeError(secondControl, 'oneCantExistsWithoutAnother');
      } else if (firstControlValue && secondControlValue) {
        ValidatorHelper.removeError(firstControl, 'oneCantExistsWithoutAnother');
        ValidatorHelper.removeError(secondControl, 'oneCantExistsWithoutAnother');
      } else if (firstControlValue || !secondControlValue) {
        ValidatorHelper.removeError(firstControl, 'oneCantExistsWithoutAnother');
        secondControl.setErrors({oneCantExistsWithoutAnother: true});
      } else {
        ValidatorHelper.removeError(secondControl, 'oneCantExistsWithoutAnother');
        firstControl.setErrors({oneCantExistsWithoutAnother: true});
      }
    };
  };

  static readonly MustMatch = (
    controlName: string,
    matchingControlName: string
  ) => {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({mustMatch: true});
      } else {
        matchingControl.setErrors(null);
      }
    };
  };

  static conditionalValidator(condition: () => boolean, ...validators) {
    return (formControl => {
      if (!formControl.parent) {
        return null;
      }
      if (condition()) {
        for (const validator of validators) {
          const errors = validator(formControl);
          if (errors) {
            return errors;
          }
        }
      }
      return null;
    })
  }

  static atLeastOneFulfilled = (validators: (ValidatorFn | null | undefined)[]) => (group: FormGroup): ValidationErrors | null => {
    const atLeastOneFulfilled = validators
      .map(validator => validator(group))
      .filter(errors => errors === null)
      .length > 0;
    return atLeastOneFulfilled ? null : {
      atLeastOneFulfilled: true
    };
  }
}
