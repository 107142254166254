import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {EventBusService} from "../../../core/services/event-bus.service";
import {ImporterService} from "../../../core/services/importer.service";
import {MatDialogRef} from "@angular/material/dialog";
import {IBase64File} from "../../model/interface/base64-file.model.interface";
import {tap} from "rxjs/operators";
import {DictionaryPosition} from "../../model/dictionary.model";
import {Observable} from "rxjs";
import {Base64FileHelper} from "../../helper/base64-file.helper";

@Component({
  selector: 'app-import-valuation',
  templateUrl: './import-valuation.component.html',
  styleUrls: ['./import-valuation.component.css']
})
export class ImportValuationComponent implements OnInit {
  importValuationFormGroup: FormGroup;
  innerFormGroup: FormGroup;

  constructor(private _formBuilder: FormBuilder,
              private _eventBusService: EventBusService,
              private _importerService: ImporterService,
              private _dialogRef: MatDialogRef<ImportValuationComponent>) {
    this._createForm();
    this._createInnerForm();
  }

  ngOnInit(): void {
  }

  importValuations() {
    const payload = this._createImportValuationsPayload();
    const request = this._createImportValuationsRequest(payload);
    return this._handleRequest(request);
  }

  private _createForm() {
    this.importValuationFormGroup = this._formBuilder.group({
      lessorId: [null, Validators.required],
      file: [null, Validators.required]
    })
  }

  private _createInnerForm() {
    this.innerFormGroup = this._formBuilder.group({
      lessorId: [null, Validators.required],
      file: [null, Validators.required]
    })
    this.importValuationFormGroup.valueChanges.subscribe(value => {
      if (value.file) {
        Base64FileHelper.fromFile(value.file)
            .subscribe(mapped => this.innerFormGroup.patchValue({
              lessorId: value.lessorId,
              file: mapped,
            }))
      }
    })
  }


  get lessor() {
    return this.importValuationFormGroup.get('lessorId') as FormControl;
  }

  get file() {
    return this.importValuationFormGroup.get('file') as FormControl;
  }

  private _createImportValuationsRequest(payload: IBase64File) {
    return this._importerService.importValuations(this.innerFormGroup.value.lessorId, payload)
        .pipe(
            tap(_ => this._eventBusService.display(DictionaryPosition.OK))
        )
  }

  private _createImportValuationsPayload(): IBase64File {
    return this.innerFormGroup.value.file;
  }

  private _handleRequest(request: Observable<any>) {
    return request.subscribe(_ => this._dialogRef.close(true));
  }
}
