import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {RequestProcessor} from "../../utils/request-processor";
import {LegalCaseExportService} from "../../../core/services/legal-case-export.service";
import {ILegalCaseSearch} from "../../model/interface/legal-case-search.model.interface";
import {IExcelExportRequest} from "../../model/interface/iexcel-export-request.interface";
import moment from "moment";
import {SnackBarService} from "../../../core/services/snack-bar.service";

@Component({
  selector: 'app-legal-case-summary-component',
  templateUrl: './legal-case-summary.component.html',
  styleUrls: ['./legal-case-summary.component.scss']
})
export class LegalCaseSummaryComponent implements OnInit {
  form: FormGroup;
  columnForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private requestProcessor: RequestProcessor,
              private legalCaseExportService: LegalCaseExportService,
              private snackBarService: SnackBarService,
              private matDialogRef: MatDialogRef<LegalCaseSummaryComponent>) {
    this.form = this.formBuilder.group({
      instructingId: null,
      instructingGroupId: null,
      onGoing: true,
      endAt: {
        from: null,
        to:  null,
      }
    })
    this.columnForm = this.formBuilder.group({
      columns: null
    })
  }

  ngOnInit(): void {
    this.columnForm.valueChanges.subscribe(_ => this.columnForm.valid)
  }


  export() {
    const search = this._createPayload(this.form.value)
    const exportConfig = this._createExportConfig(this.columnForm.get('columns').value)
    this.legalCaseExportService.exportAsync(search, exportConfig)
        .subscribe({
          next: () => {
            this.snackBarService.displayText("Zlecono generowanie raportu.");
            this.matDialogRef.close();
          },
          error: () => this.snackBarService.displayText("Coś poszło nie tak"),
          complete: () => console.info('complete')
        });
  }

  private _createPayload(form): ILegalCaseSearch {
    return {
      orEndAfter: form.endAt?.from?.format('YYYY-MM-DD'),
      orEndBefore: form.endAt?.to?.format('YYYY-MM-DD'),
      instructingId: form.instructingId,
      instructingGroupId: form.instructingGroupId,
      orOnGoing: form.onGoing
    } as ILegalCaseSearch
  }

  setPreviousMonth() {
    this.form.patchValue({
      endAt: {
        from: moment().subtract(1, 'month').startOf('month'),
        to:  moment().subtract(1, 'month').endOf('month'),
      }
    })
  }

  setCurrentMonth() {
    this.form.patchValue({
      endAt: {
        from: moment().startOf('month'),
        to:  moment().endOf('month'),
      }
    })
  }

  private _createExportConfig(value): IExcelExportRequest {
    const columns = value.columns.flat()
        .filter(column => column.select)
        .map(({select, ...rest}) => rest);
    return {
      columns
    } as IExcelExportRequest;
  }
}
