
<div class="select-buttons">
    <button mat-raised-button
            color="primary"
            (click)="selectAll()">
        Zaznacz wszystko
    </button>
    <button mat-raised-button
            color="accent"
            (click)="unselectAll()">
        Odznacz wszystko
    </button>
</div>
<form [formGroup]="form">
    <div formArrayName="columns" class="column-forms">
        <ng-container #container></ng-container>
    </div>
</form>