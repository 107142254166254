import {Component, Input} from '@angular/core';
import {ColumnFormComponent} from "../column-form.component";
import {map} from "rxjs/operators";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ISimpleSelectableDataList} from "../../model/interface/simple-selectable-data.model.interface";
import {IExcelColumn} from "../../model/interface/excel-column.interface";
import {ContractItemService} from "../../../core/services/contract-item.service";
import {CustomValidators} from "../../validator/customValidators";

@Component({
  selector: 'app-item-properties-column-form',
  templateUrl: './item-properties-column-form.component.html',
  styleUrls: ['./item-properties-column-form.component.css']
})
export class ItemPropertiesColumnFormComponent extends ColumnFormComponent {
  @Input() column: IExcelColumn;
  properties: ISimpleSelectableDataList;
  form: FormGroup;
  innerForm: FormArray;

  get select() {
    return this.form.get('select');
  }

  get props() {
    return this.form.get('properties');
  }

  get columnForm(): FormGroup {
    return this.form;
  }

  get resultForm(): FormArray {
    return this.innerForm;
  }

  constructor(private fb: FormBuilder,
              private contractItemService: ContractItemService) {
    super();
    this.form = this.createColumnForm();
    this.innerForm = this.createInnerForm()

    this.select.valueChanges
      .subscribe(_ => this.props.updateValueAndValidity());


    this.form.valueChanges
        .pipe(map(_ => this.flat(this.form.value)))
      .subscribe(controls => this.replaceInnerFormWithControls(controls));


  }

  private replaceInnerFormWithControls(controls: FormGroup[]) {
    this.innerForm.clear();
    controls.forEach(c => this.innerForm.push(c));
  }

  private createInnerForm() {
    return this.fb.array([]);
  }

  private createColumnForm() {
    return this.fb.group({
      [this.TYPE_FIELD]: [null, Validators.required],
      select: [false],
      properties: [[], CustomValidators.conditionalValidator(() => this.select.value,
        Validators.required)]
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.fetchContractItemPropertyTypes();
  }

  flat(form): FormGroup[] {
    return form.properties?.map(
      property => this.fb.group({
        [this.TYPE_FIELD]: form[this.TYPE_FIELD],
        select: form.select,
        property: property
      })
    )
  }

  private fetchContractItemPropertyTypes() {
    this.contractItemService.getAllAvailableContractItemPropertyTypes().pipe(
      map(data => data.toSimpleSelectableDataList())
    ).subscribe(data => this.properties = data);
  }

}
