<mat-form-field [appearance]="appearance" class="width-100">
  <mat-progress-bar mode="buffer" *ngIf="!data"></mat-progress-bar>
  <mat-label>{{label}}</mat-label>
  <mat-select class="width-100"
              [formControl]="selectFormControl"
              [placeholder]="label"
              [compareWith]="compareWith"
              [required]="required"
              [matTooltip]="selectedValuesString"
              multiple>
    <mat-select-trigger>
      {{selectFormControl.value ? selectedValues[0] : ''}}
      <span *ngIf="selectFormControl.value?.length > 1" class="example-additional-selection">
        (+{{selectFormControl.value.length - 1}} {{selectFormControl.value?.length === 2 ? 'inny' : 'inne'}})
      </span>
    </mat-select-trigger>
    <mat-form-field class="autocomplete-field">
      <input appSelectSearch matInput/>
    </mat-form-field>
    <mat-option *ngFor="let instance of data?.data" [value]="instance.value">
      {{instance.label}}
      <button mat-button
              matSuffix
              mat-icon-button
              appClickStopPropagation
              *ngIf="instance.tooltip"
              (click)="peek(instance)">
        <mat-icon>visibility</mat-icon>
      </button>
    </mat-option>
  </mat-select>
  <button mat-button
          matSuffix
          mat-icon-button
          appClickStopPropagation *ngIf="this.selectFormControl.value"
          (click)="clear()">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
